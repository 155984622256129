import request from './request'

//权限列表
export const RouterIndexRequest = p => {
    return request({
        method:'GET',
        url:'router/index',
        params: p
    })
}

//添加权限
export const RouterAddRequest = d => {
    return request({
        method:'POST',
        url:'router/add',
        data: d
    })
}

//删除权限
export const RouterDelRequest = id => {
    return request({
        method:'DELETE',
        url:'router/del',
        data: {
            id: id
        }
    })
}

//修改权限
export const RouterEditRequest = data => {
    return request({
        method:'PUT',
        url:'router/edit',
        data
    })
}

//获取权限详情
export const RouterDetailsRequest = id => {
    return request({
        method:'GET',
        url:'router/details',
        params: {
            id
        }
    })
}