<template>
    <div v-loading.fullscreen.lock="loading">
        <router-search-bar-component
            AddAuth="新增权限"
            :AllRouterTeam="AllRouterTeam"
            @search="get_route_index"
            @addTeam="addTeam"
        ></router-search-bar-component>
        <common-table-component
            details_auth="权限详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_route"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_route_index"
        ></common-page-component>
        <router-edit-component
            edit_auth="修改权限"
            del_auth="删除权限"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :route_details_data="froute_details_data"
            :AllRouterTeam="AllRouterTeam"
            @exitRouteDialog="dialogExit"
            @search="get_route_index"
            @show_edit="show_edit"
            @cancel_edit="cancel_edit"
            @details_row="details_route"
        ></router-edit-component>
    </div>
</template>

<script>
import { RouterIndexRequest,RouterDetailsRequest } from '@/network/Router.js'
import { RouterTeamListRequest } from '@/network/RouterTeam.js'

import RouterSearchBarComponent from '@/components/Router/RouterSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import RouterEditComponent from '@/components/Router/RouterEditComponent'

export default {
    name: 'RouteView',
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                name: '',
                team_id: '',
                is_menu:'',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'icon',
                    label: '图标',
                    width: '70px',
                    html: (row) => {
                        return row.icon ? '<img src="'+this.$store.state.baseURL+'icon/'+row.icon+'.png" height="30px"></img>' : ''
                    }
                },
                {
                    prop: 'name',
                    label: '权限名称',
                    minWidth: '100px'
                },
                {
                    prop: 'is_menu',
                    label: '菜单',
                    width: '70px',
                    tag: (d) => {
                        if( d ) {
                            return {
                                type: 'success',
                                filter_data: '是'
                            }
                        }

                        return {
                                type: 'danger',
                                filter_data: '否'
                            }
                    }
                },
                {
                    prop: 'team_name',
                    label: '所属分组',
                    minWidth: '100px'
                },
                {
                    prop: 'create_staff_name',
                    label: '最后修改人',
                    minWidth: '200px'
                },
                {
                    prop: 'create_time',
                    label: '最后修改时间',
                    minWidth: '160px'
                }
            ],
            froute_details_data: {},
            AllRouterTeam: []
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        cancel_edit() {
            this.edit_type = 0
        },
        get_route_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.team_id = param.team_id ?? this.cond.team_id
            this.cond.is_menu = param.is_menu ?? this.cond.is_menu
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            RouterIndexRequest(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_route(id) {
            this.fid = id
            this.fdialogFormVisible = true
            RouterDetailsRequest(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.froute_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            //解决弹窗有些数据不清空的BUG
            this.froute_details_data = {}
            this.fid = 0
            this.edit_type = 0
        },
        get_all_router_team() {
            this.loading = true
            RouterTeamListRequest()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.AllRouterTeam = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_route_index()
        this.get_all_router_team()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        RouterSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        RouterEditComponent
    },
    watch: {},
}
</script>

<style lang='less' scoped></style>