<template>
    <el-card class="box_card">
        <el-table
            :header-cell-style="{fontSize:'14px'}"  
            :data="filter_table_data"
            border
            stripe
            highlight-current-row
            :size="table_size"
            style="width: 100%"
            @selection-change="rows=>$emit('selects',rows)"
        >
            <el-table-column v-if="duoxuan" type="selection" width="35"></el-table-column>
            <el-table-column align="center" type="index"></el-table-column>
            <el-table-column
                v-for="item in col_arr"
                :align="col_align(item)"
                :label="item.label"
                :min-width="col_minWidth(item)"
                :width="col_width(item)"
                :show-overflow-tooltip="col_show_overflow_tooltip(item)"
                :key="item.prop"
            >
                <template v-if="item.filter" v-slot:header>
                    <el-input
                        v-model="search[item.prop]"
                        size="mini"
                        :placeholder="item.label"
                    />
                </template>
                <template slot-scope="scope">
                    <el-tag
                        v-if="item.tag"
                        :type="item.tag(scope.row[item.prop]).type"
                        :size="item.tag(scope.row[item.prop]).size ? item.tag(scope.row[item.prop]).size : 'medium'"
                        >{{
                            item.tag(scope.row[item.prop]).filter_data
                        }}</el-tag
                    >
                    <el-avatar
                        v-else-if="item.avatar"
                        :style="item.avatar"
                        :src="scope.row[item.prop]"
                        fit="cover"
                    >
                    </el-avatar>
                    <i v-else-if="item.icon" :class="'font_family '+scope.row[item.prop]"></i>
                    <span v-else-if="item.editdata">{{item.editdata(scope.row)}}</span>
                    <span v-else-if="item.html" v-html="item.html(scope.row)"></span>
                    <span v-else>{{ scope.row[item.prop] }}</span>
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                align="center"
                :width="test"
            >
                <template slot-scope="scope">
                    <el-button
                        v-if="button_name==='详情' && $_has(details_auth)"
                        plain
                        size="mini"
                        @click.native="details(scope.row.id)"
                        >详情</el-button
                    >
                    <el-button
                        v-else-if="button_name==='删除' && $_has(details_auth)"
                        type="danger"
                        plain
                        size="mini"
                        @click.native="del(scope.row.id)"
                        >删除</el-button
                    >
                    <el-button
                        v-else-if="button_name==='选择'"
                        plain
                        size="mini"
                        @click.native="sel(scope.row)"
                        >选择</el-button
                    >
                    <el-button
                        v-else-if="button_name==='查看'"
                        plain
                        size="mini"
                        @click.native="watch(scope.row)"
                        >查看</el-button
                    >
                    <el-button
                        v-if="button_namedel==='删除'"
                        type="danger"
                        plain
                        size="mini"
                        @click.native="del(scope.row.id)"
                        >删除</el-button
                    >
                </template>
                
            </el-table-column>
        </el-table>
    </el-card>
</template>

<script>
export default {
    name: 'CommonTableComponent',
    data() {
        return {
            search: {}
        }
    },
    computed: {
        filter_table_data() {
            const col_arr = this.col_arr
            const search = this.search
            return this.table_data.filter(iteam=>{
                let res = true
                col_arr.map(v=>v.prop).forEach(e => {
                    iteam[e] = iteam[e] === null ? '' : iteam[e]
                    if((search[e]) && (iteam[e].indexOf(search[e]) === -1)) {
                        res = false
                    }
                })
                
                return res
            })
            
        },
        test() {
            return this.button_namedel ? '150px' : '80px';
        }
    },
    methods: {
        col_align(col_data) {
            return col_data.align ?? 'center'
        },
        col_minWidth(col_data) {
            return col_data.minWidth ?? ''
        },
        col_width(col_data) {
            return col_data.width ?? ''
        },
        col_show_overflow_tooltip(col_data) {
            return col_data.show_overflow_tooltip ?? false
        },
        details(id) {
            this.$emit('details_row', id)
        },
        del(id) {
            this.$emit('del_row', id)
        }, 
        sel(row) {
            this.$emit('sel_row', row)
        },
        watch(row) {
            this.$emit('watch_row', row)
        }
    },
    filters: {},
    created() {},
    destoryed() {},
    mounted() {},
    updated() {
        this.$nextTick(() => {
            document.getElementById('el_main').scrollTop = 0;
        })
    },
    components: {},
    props: {
        col_arr: Array,
        table_data: Array,
        details_auth: {
            type: String,
            default() {
                return '不显示按钮'
            }
        },
        table_size: {
            type: String,
            default() {
                return 'medium'
            }
        },
        button_name: {
            type: String,
            default() {
                return '详情'
            }
        },
        button_namedel: {
            type: String,
            default() {
                return ''
            }
        },
        duoxuan: {
            type: Boolean,
            default() {
                return false
            }
        }
    }
}
</script>
