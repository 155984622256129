<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="520px"
            :center="true"
            top="20vh"
            :close-on-click-modal="false"
            :before-close="() => closeRouteDialog('RouteEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="Route"
                    :rules="rules"
                    ref="RouteEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-descriptions
                            class="margin-top"
                            :column="1"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>权限名称</span>
                                </template>
                                <el-input v-model="Route.name" clearable></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>后端路由</span>
                                </template>
                                <el-input v-model="Route.url" clearable></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>所属分组</span>
                                </template>
                                <el-select
                                v-model="Route.team_id"
                                filterable
                                clearable
                                placeholder="选择分组"
                                class="team_idc"
                                >
                                    <el-option
                                        v-for="item in AllRouterTeam"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>菜单显示</span>
                                </template>
                                <el-radio-group v-model="Route.is_menu" @change='e => clickGroup(e)'>
                                <el-radio
                                    :label=1
                                    :key=1
                                    >是</el-radio
                                >
                                <el-radio
                                    :label=0
                                    :key=0
                                    >否</el-radio
                                >
                            </el-radio-group>
                            </el-descriptions-item>
                            <el-descriptions-item v-if="Route.is_menu === 1">
                                <template slot="label">
                                    <span>图标样式代码</span>
                                </template> 
                                <el-input v-model="Route.icon" clearable></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item v-if="Route.is_menu === 1">
                                <template slot="label">
                                    <span>前端路由</span>
                                </template>
                                <el-input v-model="Route.router" clearable></el-input>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="1"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>权限名称</span>
                                </template>
                                <span>{{Route.name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>后端路由</span>
                                </template>
                                <span>{{Route.url}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>所属分组</span>
                                </template>
                                <span>{{Route.team_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>菜单显示</span>
                                </template>
                                <el-tag v-if="Route.is_menu" type="success">是</el-tag>
                                <el-tag v-else type="danger">否</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item v-if="Route.is_menu === 1">
                                <template slot="label">
                                    <span>图标样式代码</span>
                                </template>
                                <span>{{Route.icon}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item v-if="Route.is_menu === 1">
                                <template slot="label">
                                    <span>前端路由</span>
                                </template>
                                <span>{{Route.router}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="RouteDel('RouteEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeRouteDialog('RouteEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('RouteEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('RouteEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeRouteDialog('RouteEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('RouteEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="$emit('cancel_edit')"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { RouterAddRequest,RouterDelRequest,RouterEditRequest } from '@/network/Router.js'

export default {
    name: 'RouteEditComponent',
    data() {
        return {
            login_loading: false,
            Route: {
                name: '',
                url: '',
                team_id: '',
                is_menu: '',
                icon: '',
                router: ''
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入权限名称',
                        trigger: 'blur',
                    },
                    {
                        min: 2,
                        max: 16,
                        message: '长度在 2 到 16 个字符',
                        trigger: 'blur',
                    },
                ],
                url: [
                    {
                        required: true,
                        message: '请输入接口地址',
                        trigger: 'blur',
                    }
                ],
                team_id: [
                    {
                        required: true,
                        message: '请选择分组',
                        trigger: 'blur',
                    }
                ],
                is_menu: [
                    {
                        required: true,
                        message: '请选择菜单是否显示',
                        trigger: 'blur',
                    }
                ]
            },
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '权限详情'
            } else if (this.show_type === 1) {
                return '添加权限'
            } else {
                return '修改权限'
            }
        },
    },
    methods: {
        closeRouteDialog(formName) {
            this.Route = {
                name: '',
                url: '',
                team_id: '',
                is_menu: '',
                icon: '',
                router: ''
            }
            this.$emit('exitRouteDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.RouteInsert(formName)
            })
        },
        RouteInsert(formName) {
            this.login_loading = true
            RouterAddRequest(this.Route)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitRouteDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.Route = {
                name: '',
                url: '',
                team_id: '',
                is_menu: '',
                icon: '',
                router: ''
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.RouteEdit(formName)
            })
        },
        RouteEdit(formName) {
            this.login_loading = true
            RouterEditRequest(this.Route)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitRouteDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.Route.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        RouteDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    RouterDelRequest(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeRouteDialog(formName))
                })
                .catch((err) => {})
        },
        clickGroup(e) {
            if( e === 0) {
                this.Route.icon = ''
                this.Route.router = ''
            }
        }
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        route_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        AllRouterTeam: {
            type: Array,
            default() {
                return []
            },
        },
        edit_auth: String,
        del_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        route_details_data(newVal) {
            this.Route = newVal
        },
    },
}
</script>

<style lang='less' scoped>
.team_idc {
    width: 100%
}
</style>