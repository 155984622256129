import request from './request'

//权限分组列表
export const RouterTeamIndexRequest = p => {
    return request({
        method:'GET',
        url:'RouterTeam/index',
        params: p
    })
}

//添加权限分组
export const RouterTeamAddRequest = d => {
    return request({
        method:'POST',
        url:'RouterTeam/add',
        data: d
    })
}

//删除权限分组
export const RouterTeamDelRequest = id => {
    return request({
        method:'DELETE',
        url:'RouterTeam/del',
        data: {
            id: id
        }
    })
}

//修改权限分组
export const RouterTeamEditRequest = data => {
    return request({
        method:'PUT',
        url:'RouterTeam/edit',
        data
    })
}

//获取权限分组详情
export const RouterTeamDetailsRequest = id => {
    return request({
        method:'GET',
        url:'RouterTeam/details',
        params: {
            id
        }
    })
}

//权限分组序列
export const RouterTeamListRequest = p => {
    return request({
        method:'GET',
        url:'RouterTeam/list',
        params: p
    })
}